export const MENU_LIST = {
    fishing: [
        {
            id: 1,
            name: 'f_techplay',
            url: '/games/fishing?partner=techplay',
            key: 'techplay',
            isHot: true,
        },
        {
            id: 4,
            name: 'f_jili',
            url: '/games/fishing?partner=jili',
            key: 'jili',
            isNew: true,
        },
        {
            id: 2,
            name: 'f_jdb',
            url: '/games/fishing?partner=jdb',
            key: 'jdb',
        },
        {
            id: 3,
            name: 'f_fachai',
            url: '/games/fishing?partner=fachai',
            key: 'fachai',
        },
    ],
    sport: {
        sportBook: [
            {
                id: 3,
                name: 'ksport',
                key: 'ksport',
                url: '/ksports',
                isHot: true,
            },

            {
                id: 2,
                name: 'saba',
                key: 'saba',
                url: '/osports',
                isHot: false,
            },
            {
                id: 1,
                name: 'bti',
                key: 'bti',
                url: '/btisports',
                isHot: false,
                isRequireLogin: true,
            },
        ],
        virtualSports: [
            {
                id: 6,
                name: 'ksport2',
                key: 'ksport',
                url: '/ksports?isvirtual=true',
                isHot: false,
            },
            {
                id: 4,
                name: 'saba2',
                key: 'saba',
                url: '/saba-sports',
                isHot: false,
            },
            {
                id: 5,
                name: 'in_play',
                key: 'in_play',
                url: '/in-play',
                isHot: false,
            },

            {
                id: 7,
                name: 'betradar',
                key: 'betradar',
                url: '/betradar-sports',
                isHot: false,
            },
        ],
    },
    live_casino: [
        {
            id: 1,
            name: 'vivo',
            url: '/casino/all?partner=vivo',
            key: 'vivo',
            isHot: true,
        },
        {
            id: 6,
            name: 'kingmaker',
            url: '/casino/all?partner=kingmaker',
            key: 'kingmaker',
            isNew: true,
        },
        {
            id: 7,
            name: 'dreamgaming',
            url: '/casino/all?partner=dreamgaming',
            key: 'dreamgaming',
            isNew: true,
        },
        {
            id: 2,
            name: 'pragmatic',
            url: '/casino/all?partner=pragmatic',
            key: 'pragmatic',
            isHot: false,
        },
        {
            id: 3,
            name: 'evolution',
            url: '/casino/all?partner=evo',
            key: 'evo',
            isHot: false,
        },
        {
            id: 4,
            name: 'ezugi',
            url: '/casino/all?partner=ezugi',
            key: 'ezugi',
            isHot: false,
        },

        {
            id: 5,
            name: 'microgaming',
            url: '/casino/all?partner=mg',
            key: 'mg',
            isHot: false,
        },
    ],
    slots: [
        {
            id: 2,
            name: 's_techplay',
            url: '/games/slots?partner=techplay',
            key: 'techplay',
            isHot: true,
        },
        {
            id: 7,
            name: 's_playngo',
            url: '/games/slots?partner=playngo',
            key: 'playngo',
            isNew: true,
        },
        {
            id: 8,
            name: 's_evoplay',
            url: '/games/slots?partner=evoplay',
            key: 'evoplay',
            isNew: true,
        },
        {
            id: 1,
            name: 's_pgsoft',
            url: '/games/slots?partner=pgsoft',
            key: 'pgsoft',
            isHot: false,
        },
        {
            id: 3,
            name: 's_pragmatic',
            url: '/games/slots?partner=pragmatic',
            key: 'pragmatic',
            isHot: false,
        },
        {
            id: 4,
            name: 's_redtiger',
            url: '/games/slots?partner=redtiger',
            key: 'redTiger',
            isHot: false,
        },

        {
            id: 5,
            name: 's_microgaming',
            url: '/games/slots?partner=microgaming',
            key: 'microgaming',
            isHot: false,
        },
        {
            id: 6,
            name: 's_qtech',
            url: '/games/slots?partner=qtech',
            key: 'qtech',
            isHot: false,
        },
    ],
    cock_fight: [
        {
            id: 1,
            name: 'cockfight-1',
            url: '/gameUrl?partnerProvider=cockfight&partnerGameId=lobby',
            key: 'digmaan',
        },
    ],
    table_game: [
        {
            id: 1,
            name: 't_techplay',
            url: '/games/tables?partner=techplay',
            key: 'techplay',
            isHot: true,
        },
        {
            id: 3,
            name: 't_jili',
            url: '/games/tables?partner=jili',
            key: 'jili',
            isNew: true,
        },
        {
            id: 4,
            name: 't_playngo',
            url: '/games/tables?partner=playngo',
            key: 'playngo',
            isNew: true,
        },
        {
            id: 2,
            name: 't_netent',
            url: '/games/tables?partner=netent',
            key: 'netent',
        },
    ],
    lottery: [
        // Comment out QUAYSO1
        // {
        //     id: 1,
        //     name: 'lot_techplay',
        //     url: '/lottery/lottery1',
        //     key: 'techplay',
        // },
        {
            id: 4,
            name: 'l_techplay',
            url: '/games/lottery?partner=techplay',
            key: 'techplay',
            isHot: true,
        },
        {
            id: 1,
            name: 'l_askmelotto',
            url: '/games/lottery?partner=askmelotto',
            key: 'lottery',
            isNew: true,
        },
        {
            id: 2,
            name: 'l_ibc',
            url: '/games/lottery?partner=ibc',
            key: 'lottery',
        },
        {
            id: 3,
            name: 'l_spribe',
            url: '/games/lottery?partner=spribe',
            key: 'spribe',
        },
    ],
    quick_game: [
        // {
        //     id: 2,
        //     name: 'q_techplay',
        //     url: '/games/instants?partner=techplay',
        //     key: 'techplay',
        //     isHot: true,
        // },
        {
            id: 3,
            name: 'q_evoplay',
            url: '/games/instants?partner=evoplay',
            key: 'evoplay',
            isHot: true,
        },
        {
            id: 1,
            name: 'q_spribe',
            url: '/games/instants?partner=spribe',
            key: 'spribe',
            isNew: true,
        },
    ],
    esports: [
        {
            id: 1,
            name: 'esports-2',
            key: 'saba_esports',
            url: '/saba-sports',
        },
        {
            id: 2,
            name: 'esports-1',
            key: 'im_esports',
            url: '/im-sports',
        },
    ],
}
